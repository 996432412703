<template>
  <layout-vertical>
    <router-view />

    <app-breadcrumb slot="breadcrumb" />

    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-vertical>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    AppBreadcrumb,
    AppNavbarVerticalLayout,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
